<template>
  <div class="row p-0 m-0">
    <div class="col-12 d-flex p-0 justify-content-start">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des référents
      </span>

      <CustomAddButton
        text="Ajouter rôle"
        :disabled="disabled"
        @click="searchRoleModal = !searchRoleModal"
      />
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div>
      <!-- Search role -->
      <div to="modals-xyz-548" v-if="searchRoleModal">
        <Modal @close="searchRoleModal = !searchRoleModal">
          <search-role
            :libelle="libelle"
            @cofirmRole="addRole"
            @close="searchRoleModal = !searchRoleModal"
          />
        </Modal>
      </div>

      <!-- Search guests -->
      <div to="modals-xyz-548" v-if="searchGuestsModal">
        <Modal @close="searchGuestsModal = !searchGuestsModal">
          <SearchGuests
            :libelle="libelle"
            @close="searchGuestsModal = !searchGuestsModal"
            @cofirmUser="confirmUser"
          />
        </Modal>
      </div>

      <div class="d-flex">
        <div
          v-for="(referent, index) in referentsList"
          :key="index"
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            m-0
            shdow
            px-4
            py-2
            mr-4
          "
        >
          <div>
            <p>{{ referent.role }}</p>
          </div>
          <div
            style="
              background: black;
              height: 100px;
              width: 100px;
              border-radius: 50px;
              position: relative;
            "
            class="accnt-log d-flex align-items-center justify-content-center"
          >
            <i
              v-if="!referent.user || !referent.user.avatarUrl"
              style="color: white; font-size: 40px"
              class="mdi mdi-account-outline"
            ></i>
            <img
              v-else
              class="rounded-pill"
              style="width: 96%; height: 96%; object-fit: cover"
              :src="IMG_URL + referent.user.avatarUrl"
              :alt="`${referent.user.firstname} ${referent.user.lastname}`"
            />
            <div style="position: absolute; bottom: -8px; right: 5px">
              <i
                v-if="referent.user === null"
                @click="setUserRole(index, referent.role)"
                style="
                  color: blue;
                  font-size: 25px;
                  background: white;
                  border-radius: 15px;
                  cursor: pointer;
                "
                class="mdi mdi-plus-circle"
              ></i>
              <i
                v-else-if="!disabled"
                @click="deleteUser(index, referent.role)"
                style="
                  color: red;
                  font-size: 25px;
                  background: white;
                  border-radius: 15px;
                  cursor: pointer;
                "
                class="mdi mdi-close-circle"
              ></i>
            </div>
          </div>
          <div>
            <p class="font-weight-bold my-2" v-if="referent.user">
              {{ `${referent.user.firstname} ${referent.user.lastname}` }}
            </p>
            <p class="my-2" v-else>Aucun collaborateur</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/custom/Modal.vue";
import searchRole from "./searchRole.vue";
import SearchGuests from "./SearchGuests.vue";
import { IMG_URL } from "@/helpers/services/api";
import CustomAddButton from "@/components/custom/CustomAddButton";
import Swal from "sweetalert2";

export default {
  components: {
    searchRole,
    Modal,
    SearchGuests,
    CustomAddButton,
  },
  props: {
    referents: {
      type: Array,
      default: () => [],
    },
    responsables: Array,
    libelle: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      IMG_URL,
      searchRoleModal: false,
      searchGuestsModal: false,
      selectedReferent: null,
      referentsList: this.referents.length
        ? this.referents
        : [
            {
              role: "Sponsor",
              user: null,
            },
          ],
    };
  },
  methods: {
    addRole(role) {
      this.referentsList.push({
        role: role,
        user: null,
      });
    },
    confirmUser(userId) {
      this.referentsList = this.referentsList.map((referent, index) =>
        this.selectedReferent == index
          ? {
              ...referent,
              user: this.responsables.find(
                (responsable) => responsable.id === userId
              ),
            }
          : referent
      );
      this.$store
        .dispatch("composant/updateComposant", {
          id: this.$route.params.id,
          referents: this.referentsList
            .filter((ref) => ref.user != null)
            .map((ref) => ({ ...ref, user: `/api/users/${ref.user.id}` })),
        })
        .then(() => {
          this.$store.dispatch(
            "composant/fetchComposant",
            this.$route.params.id
          );
        });
    },
    setUserRole(index, selectedRole) {
      if (!this.disabled) {
        this.selectedReferent = index;
        this.role = selectedRole;
        this.searchGuestsModal = !this.searchGuestsModal;
      }
    },
    deleteUser(index) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.referentsList = this.referentsList.filter(
            (referent, i) => i !== index
          );
          this.$store
            .dispatch("composant/updateComposant", {
              id: this.$route.params.id,
              referents: this.referentsList
                .filter((ref) => ref.user != null)
                .map((ref) => ({ ...ref, user: `/api/users/${ref.user.id}` })),
            })
            .then(() => {
              this.$store
                .dispatch("composant/fetchComposant", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
  },
  watch: {
    referents() {
      this.referentsList = this.referents;
    },
  },
};
</script>
