<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des cadres rattachés
      </span>
      <div class="d-flex justify-content-end">
        <CustomAddButton
          text="Créer cadre"
          :disabled="disabled"
          @click="addInput"
        />
        <CustomAddButton
          text="Affecter un cadre"
          :disabled="disabled"
          @click="showCadreModal = true"
          class="ml-1"
        />
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <!-- {{ CADRES.cadres }} -->
    <b-table
      :items="tableItems"
      responsive
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
      show-empty
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(fichier_joint)="data">
        <b-link>{{ data.value }}</b-link>
      </template>
      <template v-slot:cell(principe)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <b-link
            v-if="data.value"
            :to="{
              name: 'show-principe-ref',
              params: {
                id: data.value.id || '2',
                slug: data.value.slug || '621dd50372819',
              },
            }"
            >{{ data.value ? data.value.name : "-" }}
          </b-link>
          <span v-else>-</span>
        </span>
        <div v-else>
          <v-select
            :options="principesList"
            label="name"
            appendToBody
            v-model="newCadre.principe"
            placeholder="Principe"
            style="padding-right: 0 !important"
            :class="{
              'is-invalid form-control p-0':
                $v.newCadre.principe.$error && $v.newCadre.principe.$dirty,
            }"
          />
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newCadre.principe.$dirty"
          >
            {{ !$v.newCadre.principe.required ? "champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(redacteur)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <!-- {{ data.value }} -->
          <TableResponsable :responsable="data.value" />
        </span>
        <div v-else>
          <v-select
            placeholder="Redacteur"
            label="text"
            :reduce="(user) => user.value"
            :options="responsables"
            appendToBody
            v-model="newCadre.redacteur"
            style="padding-right: 0 !important"
            :class="{
              'is-invalid form-control p-0':
                $v.newCadre.redacteur.$error && $v.newCadre.redacteur.$dirty,
            }"
          >
            <template #no-options> Liste vide </template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newCadre.redacteur.$dirty"
          >
            {{ !$v.newCadre.redacteur.required ? "champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(name)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value }}
        </span>
        <div v-else>
          <b-form-input
            type="text"
            placeholder="Libellé"
            v-model="newCadre.name"
            :class="{
              'is-invalid': $v.newCadre.name.$error && $v.newCadre.name.$dirty,
            }"
          ></b-form-input>
          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newCadre.name.$dirty"
          >
            {{
              !$v.newCadre.name.required
                ? "champ obligatoire"
                : !$v.newCadre.name.minLength
                ? `Le champ doit contenir au moins ${$v.newCadre.name.$params.minLength.min} caractères.`
                : ""
            }}
          </span>
        </div>
      </template>
      <template v-slot:cell(capaciteTechnique)="data">
        <div class="text-center">
          <b-badge
            variant="primary"
            pill
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            @click="capacitelist(data.value, data.item.name)"
            >{{ data.value.length }}
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(systemesApplication)="data">
        <div class="text-center">
          <b-badge
            variant="primary"
            pill
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            >{{
              data.value.systemesApplication
                ? data.value.systemesApplication.length
                : 0
            }}
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(fileCadres)="data">
        <div class="text-center">
          <b-badge
            variant="primary"
            pill
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            @click="display(data.value, data.item.name)"
            >{{ data.value.length }}
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value }}
        </span>
        <div v-else>
          <v-select
            type="text"
            :options="['Validé', 'Deployé']"
            style="padding-right: 0 !important"
            placeholder="Statut"
            v-model="newCadre.status"
            appendToBody
            :class="{
              'is-invalid form-control p-0':
                $v.newCadre.status.$error && $v.newCadre.status.$dirty,
            }"
          >
            <template #no-options> Liste vide </template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newCadre.status.$dirty"
          >
            {{ !$v.newCadre.status.required ? "champ obligatoire" : "" }}
          </span>
        </div>
      </template>

      <template v-slot:cell(type)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value }}
        </span>
        <div v-else>
          <v-select
            type="text"
            :options="['Pattern', 'Règle', 'Politique', 'Principe']"
            style="padding-right: 0 !important"
            placeholder="Statut"
            v-model="newCadre.type"
            appendToBody
            :class="{
              'is-invalid form-control p-0':
                $v.newCadre.type.$error && $v.newCadre.type.$dirty,
            }"
          >
            <template #no-options> Liste vide </template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newCadre.type.$dirty"
          >
            {{ !$v.newCadre.type.required ? "champ obligatoire" : "" }}
          </span>
        </div>
      </template>

      <template #cell(actions)="data">
        <table-actions
          class="justify-content-end"
          :actions="!disabled ? ['delete'] : []"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem()"
          @cancelItem="cancelItem(data.item)"
          @deleteItem="deleteItem(data.item)"
          @editItem="editItem(data.item)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      aria-controls="table-list"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="right"
    >
    </b-pagination>
    <div v-if="showfile" to="modals-xyz-548">
      <modal @close="showfile = !showfile">
        <Filelist :items="data" :libelle="libelle" />
      </modal>
    </div>
    <div v-if="showlist" to="modals-xyz-548">
      <modal @close="showlist = !showlist">
        <CapaciteList :items="capacite" :libelle="name" />
      </modal>
    </div>
    <div v-if="showSysteme" to="modals-xyz-548">
      <modal @close="showSysteme = !showSysteme">
        <SystemeList :items="systeme" :libelle="name" :id="id" />
      </modal>
    </div>
    <div v-if="showCadreModal" to="modals-xyz-548">
      <modal @close="showCadreModal = !showCadreModal">
        <CadresList
          :existedCadres="tableItems"
          @close="showCadreModal = !showCadreModal"
        />
      </modal>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Filelist from "@/pages/company-reference/principes/components/Filelist.vue";
import CapaciteList from "@/pages/company-reference/principes/components/CapaciteList.vue";
import SystemeList from "@/pages/company-reference/principes/components/SystemeList.vue";
import Modal from "@/components/custom/Modal.vue";
import CadresList from "./CadresList.vue";
import { COMPOSANT_URL, USER_URL } from "@/helpers/constURL";
import TableResponsable from "@/components/custom/TableResponsable";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableActions,
    CustomAddButton,
    Filelist,
    CapaciteList,
    SystemeList,
    Modal,
    CadresList,
    TableResponsable,
  },
  props: {
    cadres: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      showfile: false,
      showlist: false,
      showSysteme: false,
      showCadreModal: false,
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedCadre: null,
      tableFields: [
        { key: "type", label: "Type cadre", sortable: true },
        { key: "name", label: "Libellé cadre", sortable: true },
        { key: "principe", label: "Principe appliqué", sortable: true },
        { key: "redacteur", label: "Rédacteur", sortable: true },
        { key: "status", label: "Status", sortable: true },
        {
          key: "systemesApplication",
          label: "Systèmes d'application",
          sortable: true,
        },
        { key: "capaciteTechnique", label: "Capacités", sortable: true },
        { key: "fileCadres", label: "Fichier joint", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
      ref: "",
      status: "",
      po: null,
      taux: 0,
      newCadre: {
        name: "",
        redacteur: "",
        type: "",
        status: "",
        principe: "",
      },
    };
  },
  validations: {
    // selectedCadre: {
    //   required,
    // },
    newCadre: {
      name: {
        required,
        minLength: minLength(5),
      },
      redacteur: {
        required,
      },
      status: {
        required,
      },
      type: {
        required,
      },
      principe: {
        required,
      },
      // responsable: {
      //   required,
      // },
    },
  },
  created() {
    // this.$store.dispatch("cadre/fetchAllCadres");
    this.$store.dispatch("principe/fetchAllPrincipes");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch(
      "composant/fetchComposantCadres",
      this.$route.params.id
    );
  },

  computed: {
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]),
    ...mapGetters("composant", ["COMPOSANT", "CADRES"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("principe", ["PRINCIPES"]),
    ...mapGetters(["AuthenticatedUser"]),

    principesList() {
      return this.PRINCIPES;
    },
    responsables() {
      return this.RESPONSABLES.map((data) => ({
        ...data,
        text: data.firstname + " " + data.lastname,
        value: data.id,
      }));
    },
    filtredCadres() {
      return this.CADRE_ENTREPRISES.map((cadre) => ({
        text: cadre.name,
        value: cadre["@id"],
      }));
    },
    rows() {
      return this.tableItems.length;
    },
  },
  methods: {
    display(id, name) {
      this.data = id;
      this.libelle = name;
      if (this.data.length) this.showfile = !this.showfile;
    },
    addInput() {
      this.tableItems = !this.tableItems.some((item) =>
        item.editMode ? item.editMode : false
      )
        ? [
            {
              editMode: true,
            },
            ...this.tableItems,
          ]
        : this.tableItems;
      if (
        !this.tableItems.some((item) => (item.editMode ? item.editMode : false))
      ) {
        this.createdAt = new Date();
      }
      this.decision = "En attente";
    },
    confirmItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        //
        var data = {
          name: this.newCadre.name,
          redacteur: `/api/${USER_URL}/${this.newCadre.redacteur}`,
          composants: [`/api/${COMPOSANT_URL}/${this.$route.params.id}`],
          type: this.newCadre.type,
          status: this.newCadre.status,
          responsable: this.AuthenticatedUser["@id"],
          principe: this.newCadre.principe["@id"],
        };
        this.$store.dispatch("cadre/createCadre", data).then(() => {
          this.$store
            .dispatch("composant/fetchComposantCadres", this.$route.params.id)
            .then(() => {
              Swal.fire("Le cadre est bien créé !", "", "success");

              this.selectedCadre = null;
            });
        });
      }
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var newCadre = this.tableItems
            .filter((cadre) => (cadre.id ? cadre.id != item.id : false))
            .map((cadre) => cadre["@id"]);
          this.$store
            .dispatch("composant/updateComposant", {
              id: this.$route.params.id,
              cadres: newCadre,
            })
            .then(() => {
              this.$store.dispatch(
                "composant/fetchComposantCadres",
                this.$route.params.id
              );
              this.$store
                .dispatch("composant/fetchComposant", this.$route.params.id)

                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },

    cancelItem() {
      if (
        this.newCadre.name === "" &&
        this.newCadre.redacteur === "" &&
        this.newCadre.type === "" &&
        this.newCadre.status === "" &&
        this.newCadre.principe === ""
      )
        this.$store.dispatch(
          "composant/fetchComposantCadres",
          this.$route.params.id
        );
      else {
        this.newCadre.name = "";
        this.newCadre.redacteur = "";
        this.newCadre.type = "";
        this.newCadre.status = "";
        this.newCadre.principe = "";
      }
    },
  },
  // mounted() {
  //   this.tableItems = this.COMPOSANT.cadres;
  // },
  watch: {
    CADRES() {
      this.tableItems = this.CADRES.cadres;
    },
    selectedCadre() {
      if (this.selectedCadre) {
        this.ref = `SA${this.selectedCadre.id}`;
        this.status = this.selectedCadre.status;
        this.taux = this.selectedCadre.taux;
      } else {
        this.ref = "";
        this.status = "";
        this.po = null;
        this.taux = 0;
      }
    },
  },
};
</script>
