<template>
  <section class="container-fluid bg-white p-5 rounded-lg">
    <div class="d-flex">
      <div class="d-flex col-12 p-0 mb-4">
        <div class="ml-auto">
          <modal-actions @close="$router.go(-1)" />
        </div>
      </div>
    </div>
    <div id="add-page" class="m-0 justify-content-between">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <span class="h2 mr-auto text-secondary"
          >Nouveau composant technique
        </span>

        <div class="ml-auto">
          <b-button @click="createItem" pill :disabled="submitingForm">
            <b-spinner
              small
              v-if="submitingForm"
              class="text-white"
              label="Loading..."
            ></b-spinner>
            Confirmer</b-button
          >
        </div>
      </div>
      <!-- <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div> -->
      <div class="col-12 mt-3 d-flex p-0 m-0" style="gap: 5px">
        <div class="row col-md-4 m-0 shdow col-12">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <span class="h4 text-secondary">Informations générales</span>
          </div>
          <div class="col-12 p-0">
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>
          <!-- First Col -->
          <div class="col-12 p-0">
            <b-form-group
              label="Libellé:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <b-form-input
                v-model="newComposant.name"
                placeholder="Libellé"
                :class="{
                  'is-invalid':
                    $v.newComposant.name.$error && $v.newComposant.name.$dirty,
                }"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.name.$dirty"
              >
                {{
                  !$v.newComposant.name.required
                    ? "Champ obligatoire"
                    : !$v.newComposant.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newComposant.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <!--  -->
            <b-form-group
              label="Editeur:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newComposant.editeur.$error &&
                    $v.newComposant.editeur.$dirty,
                }"
              >
                <v-select
                  label="name"
                  class="bg-transparent"
                  :reduce="(user) => user.id"
                  :options="editerusList"
                  placeholder="Editeur"
                  v-model="newComposant.editeur"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.editeur.$dirty"
              >
                {{
                  !$v.newComposant.editeur.required ? "Champ obligatoire" : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <!--  -->
            <b-form-group
              label="Statut:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newComposant.status.$error &&
                    $v.newComposant.status.$dirty,
                }"
              >
                <v-select
                  placeholder="Status"
                  class="bg-transparent"
                  v-model="newComposant.status"
                  :options="['Propose', 'Non valide']"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.status.$dirty"
              >
                {{
                  !$v.newComposant.status.required ? "Champ obligatoire" : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <!--  -->

            <b-form-group
              label="Type composant:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newComposant.type.$error && $v.newComposant.type.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  placeholder="Type composant"
                  v-model="newComposant.type"
                  :options="composantTypes"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.type.$dirty"
              >
                {{ !$v.newComposant.type.required ? "Champ obligatoire" : "" }}
              </span>
              <!--  -->
            </b-form-group>
            <!--  -->
            <b-form-group
              label="Taux de remplissage:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <b-progress
                class="col-md-12 p-0 m-0 my-auto"
                max="100"
                show-progress
                height="15px"
              >
                <b-progress-bar
                  :value="tauxRemplissage"
                  :label="`${tauxRemplissage}%`"
                  :style="`background-color: ${
                    tauxRemplissage > 20 && tauxRemplissage <= 70
                      ? '#f5f245'
                      : tauxRemplissage > 70
                      ? '#23d92c'
                      : '#f5ba45'
                  }`"
                ></b-progress-bar>
              </b-progress>
            </b-form-group>
            <!--  -->
          </div>

          <!-- Second Col -->
          <div class="col-12 p-0">
            <b-form-group
              label="Ligne métier:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
              >
                <multiselect
                  multiple
                  label="name"
                  track-by="id"
                  v-model="newComposant.businessLine"
                  :options="linesList"
                  placeholder="Ligne métier"
                ></multiselect>
              </div>
            </b-form-group>

            <!--  -->
            <b-form-group
              label="PO:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newComposant.archictecteTechnique.$error &&
                    $v.newComposant.archictecteTechnique.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  label="text"
                  :reduce="(user) => user.value"
                  :options="usersList"
                  placeholder="Architecture composant"
                  v-model="newComposant.archictecteTechnique"
                />
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.archictecteTechnique.$dirty"
              >
                {{
                  !$v.newComposant.archictecteTechnique.required
                    ? "Champ obligatoire"
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <!--  -->

            <b-form-group
              label="Portée composant:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newComposant.porte.$error &&
                    $v.newComposant.porte.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  placeholder="Portée composant"
                  :options="['Local']"
                  v-model="newComposant.porte"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.porte.$dirty"
              >
                {{ !$v.newComposant.porte.required ? "Champ obligatoire" : "" }}
              </span>
              <!--  -->
            </b-form-group>

            <b-form-group
              label="Patrimoine:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
              >
                <v-select
                  class="bg-transparent"
                  placeholder="Patrimoine"
                  v-model="newComposant.patrimoine"
                  label="text"
                  :reduce="(patrimoine) => patrimoine.value"
                  :options="patrimoinesList"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
            </b-form-group>
            <!--  -->
          </div>

          <!-- Third col -->
        </div>
        <div class="shdow col-12 col-md-4 m-0">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <span class="h4 text-secondary">Documents</span>
          </div>
          <div class="col-12 p-0">
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>

          <div class="col-12 m-0 p-0">
            <FilePicker
              @change="filePickerChanger"
              class="font-weight-normal"
            />
          </div>
        </div>
        <!-- Couvertute start -->
        <div class="shdow col-12 col-md-4 m-0">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <span class="h4 text-secondary">Couvertures</span>
          </div>
          <div class="col-12 p-0">
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>

          <div class="m-0 p-0">
            <Places :testCouverture="sendingFunction" />
          </div>
          <div class="row m-0 p-0">
            <div
              style="height: 50px; position: relative; overflow: visible"
              class="shdow"
              v-for="(covert, index) in couvertures"
              :key="index"
            >
              <p>
                <country-flag
                  :country="covert.countryCode"
                  size="small"
                  v-show="covert.countryCode"
                />{{ covert.country }}
              </p>

              <div style="position: absolute; top: -5px; right: -5px">
                <span style="cursor: pointer"
                  ><i
                    @click="deleteCouverture(index)"
                    class="mdi mdi-close-circle text-danger"
                  ></i
                ></span>
              </div>
            </div>
          </div>

          <div class="d-flex mt-3 justify-content-between align-items-center">
            <p class="h4 text-secondary my-auto mr-auto">Pays Responsables</p>
          </div>
          <hr class="w-100 mt-1 mb-0 bg-secondary" />
          <div class="m-0 mt-3 p-0">
            <PaysResponsable
              class="m-0"
              :paysResponsableFunction="paysResponsableFunction"
            />
          </div>
          <div class="row m-0 p-0">
            <div
              style="height: 50px; position: relative; overflow: visible"
              class="shdow"
              v-for="(covert, index) in paysResponable"
              :key="index"
            >
              <p>
                <country-flag
                  :country="covert.countryCode"
                  size="small"
                  v-show="covert.countryCode"
                />{{ covert.country }}
              </p>

              <div style="position: absolute; top: -5px; right: -5px">
                <span style="cursor: pointer"
                  ><i
                    @click="deletePaysResponsable(index)"
                    class="mdi mdi-close-circle text-danger"
                  ></i
                ></span>
              </div>
            </div>
          </div>
        </div>
        <!-- Couverture end -->
      </div>
    </div>
    <hr />

    <!-- Tabs -->
    <b-tabs class="tab-solid tab-solid-primary mt-3">
      <b-tab title="Référents"> <Referent disabled /> </b-tab>
      <b-tab title="Version">
        <VersionTable
          @editItem="$router.push({ name: 'edit-vision' })"
          @showItem="$router.push({ name: 'show-vision' })"
          :versions="[]"
          disabled
        />
      </b-tab>
      <b-tab title="Capacités">
        <CapacitesTable disabled />
      </b-tab>
      <b-tab title="Les applications supportees">
        <ApplicationTable
          @editItem="$router.push({ name: 'edit-vision' })"
          @showItem="$router.push({ name: 'show-vision' })"
          disabled
          :applications="[]"
        />
      </b-tab>
      <b-tab title="Transformation">
        <TrnsTable
          @editItem="$router.push({ name: 'edit-vision' })"
          @showItem="$router.push({ name: 'show-vision' })"
          disabled
        />
      </b-tab>
      <b-tab title="Cadres rattachés"><CadresTable disabled /></b-tab
    ></b-tabs>
  </section>
</template>

<script>
import VersionTable from "./components/VesionTable.vue";
import ApplicationTable from "./components/ApplicationTable.vue";
import TrnsTable from "./components/TransformationTable.vue";
import CountryFlag from "vue-country-flag";
import ModalActions from "@/components/custom/ModalActions.vue";
import CapacitesTable from "./components/CapacitesTable.vue";

import Referent from "./components/Referent.vue";
// import VueSlideBar from "vue-slide-bar";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import Places from "@/components/custom/Places.vue";
import PaysResponsable from "@/components/custom/PaysResponsable.vue";
import FilePicker from "@/components/custom/FilePicker.vue";
import { USER_URL } from "@/helpers/constURL";
import CadresTable from "./components/CadresTable";

export default {
  components: {
    // VueSlideBar,
    VersionTable,
    ApplicationTable,
    TrnsTable,
    CountryFlag,
    ModalActions,
    Referent,
    Places,
    PaysResponsable,
    CapacitesTable,
    FilePicker,
    CadresTable,
  },
  data: () => ({
    showApps: true,
    submitingForm: false,

    location: null,
    newComposant: {
      name: "",
      type: "",
      taux: 0,
      status: "",
      capaciteTechnique: "",
      businessLine: [],
      editeur: "",
      porte: "",
      archictecteTechnique: "",
      patrimoine: "",
    },
    couvertures: [],
    livrables: [],
    paysResponable: [],
  }),
  validations: {
    newComposant: {
      name: {
        required,
        minLength: minLength(5),
      },
      type: {
        required,
      },
      status: {
        required,
      },

      archictecteTechnique: {
        required,
      },
      // capaciteTechnique: {
      //   required,
      // },
      editeur: {
        required,
      },
      porte: {
        required,
      },
    },
  },
  methods: {
    filePickerChanger(files) {
      this.livrables = files;
    },
    deleteCouverture(index) {
      this.couvertures.splice(index, 1);
    },
    deletePaysResponsable(index) {
      this.paysResponable.splice(index, 1);
    },

    paysResponsableFunction(location) {
      (!this.paysResponable.find(
        (pays) => pays.countryCode === location.countryCode
      ) &&
        this.paysResponable.push(this.clone(location))) ||
        Swal.fire("Impossible d'ajouter un pays déjà existant !", "", "error");
    },
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    sendingFunction: function (location) {
      (!this.couvertures.find(
        (couverture) => couverture.countryCode === location.countryCode
      ) &&
        this.couvertures.push(this.clone(location))) ||
        Swal.fire(
          "Impossible d'ajouter une couverture déjà existante !",
          "",
          "error"
        );
    },
    createItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        (this.submitingForm = true),
          this.$store
            .dispatch("composant/createComposant", {
              ...this.newComposant,
              // capaciteTechnique: this.newComposant.capaciteTechnique["@id"],
              taux: parseFloat(this.tauxRemplissage),
              avancement: "0",
              businessLine: this.newComposant.businessLine.map(
                (line) => line["@id"]
              ),
              couvertures: this.couvertures.map((element) => ({ ...element })),
              couvertureResps: this.paysResponable.map((element) => ({
                ...element,
              })),
            })
            .then(() => {
              this.submitingForm = false;
            });
      }
    },

    addTableRow() {
      this.versions.tableItems.push({ editMode: true });
    },
    addAppRow() {
      this.apps.tableItems.push({ editMode: true });
    },
    addTransRow() {
      this.trans.tableItems.push({ editMode: true });
    },
  },
  created() {
    this.$store.dispatch("capaciteTechnique/fetchAllCapacites");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("businessLine/fetchAllBusinessLines");
    this.$store.dispatch("editeur/fetchAllEditeurs");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("role/fetchAllContributorRoles");
  },
  computed: {
    ...mapGetters("capaciteTechnique", ["CAPACITES_TECHNIQUES"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("businessLine", ["BUSINESS_LINES"]),
    ...mapGetters("composant", ["COMPOSANT"]),
    ...mapGetters("editeur", ["EDITEURS"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("role", ["CONTRIBUTOR_ROLES"]),

    capacitesList() {
      return this.CAPACITES_TECHNIQUES;
    },
    usersList() {
      return this.RESPONSABLES.map((user) => ({
        text: `${user.firstname} ${user.lastname}`,
        value: `/api/${USER_URL}/${user.id}`,
      }));
    },
    linesList() {
      return this.BUSINESS_LINES;
    },
    editerusList() {
      return this.EDITEURS.map((editeur) => ({
        ...editeur,
        id: editeur["@id"],
      }));
    },
    patrimoinesList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        text: patrimoine.name,
        value: patrimoine["@id"],
      }));
    },
    tauxRemplissage() {
      let i = 0;
      if (this.newComposant.name) i++;
      if (this.newComposant.type) i++;
      if (this.newComposant.status) i++;
      // if(this.newComposant.capaciteTechnique) i++
      if (this.newComposant.businessLine.length) i++;
      if (this.newComposant.editeur) i++;
      if (this.newComposant.porte) i++;
      if (this.newComposant.archictecteTechnique) i++;
      if (this.newComposant.patrimoine) i++;
      if (this.couvertures.length) i++;
      if (this.livrables.length) i++;
      if (this.paysResponable.length) i++;

      return ((i / (13 + this.CONTRIBUTOR_ROLES.length)) * 100).toFixed(2);
    },
    composantTypes() {
      return [
        "BDD",
        "Framework logiciel",
        "Hébergement",
        "Réseau & Sécurité",
        "Système d’échange",
        "Autre",
      ];
    },
  },
  watch: {
    COMPOSANT() {
      this.submitingForm = false;

      this.$store.dispatch("referent/createReferent", {
        composant: this.COMPOSANT["@id"],
        user: this.COMPOSANT.archictecteTechnique["@id"],
        role: "Teach Lead",
      });
      this.livrables.map((file) => {
        file.append("composant", this.COMPOSANT["@id"]);
        this.$store.dispatch("composant/createFileComposant", file);
      });
      Swal.fire({
        title: "Le composant technique est bien créé !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-composant",
            params: {
              slug: this.COMPOSANT.slug,
              id: this.COMPOSANT.id,
              fromCreation: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style>
.slide-con {
  height: 20px;
  border-radius: 20px;
  background-color: rgb(125, 117, 129);
}
.slide-in {
  width: 50%;
  text-align: center;
  color: white;
  border-radius: 20px 0px 0px 20px;
  height: 100%;
  background-color: rgb(218, 230, 53);
}
.card-table .card-body {
  padding: 0px;
  padding-left: 5px;
  min-height: 400px !important;
}
</style>
