<template>
  <div class="row p-0 m-0">
    <div class="col-12 d-flex p-0 justify-content-start">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des capacités
      </span>

      <CustomAddButton
        text="Ajouter capacité "
        :disabled="disabled"
        @click="addInput"
      />
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div class="col-12 p-0">
      <div class="card-body p-0">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          bordered
          :busy="loading"
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="!disabled ? ['delete'] : []"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item)"
              @deleteItem="deleteItem(data.item)"
              @cancelItem="cancelItem(data.item)"
              @confirmItem="confirmItem(data.item)"
            />
          </template>

          <template v-slot:cell(niveau)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value || 0 }}
            </span>
            <div v-else>
              <b-form-input
                v-model="niveau"
                disabled
                type="text"
                placeholder="Niveau"
              ></b-form-input>
            </div>
          </template>

          <template v-slot:cell(type)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{
                data.value === "CapaciteSi"
                  ? "Capacité si"
                  : "Capacité technique"
              }}
            </span>

            <v-select
              v-model="type"
              v-else
              placeholder="type"
              appendToBody
              @input="selectedCapacite = null"
              :options="['Capacité si', 'Capacité technique']"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              v-if="!data.item.hasOwnProperty('editMode')"
              :to="{
                name:
                  data.item.type === 'CapaciteSi'
                    ? 'show-infosys-capacity'
                    : 'show-technical-capacity',
                params: {
                  id: data.item.id,
                  slug: data.item.slug,
                },
              }"
            >
              {{ data.value || "-" }}
            </b-link>

            <v-select
              v-model="selectedCapacite"
              label="text"
              v-else
              placeholder="libelle"
              :options="capacitesList"
              appendToBody
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators"; //validation
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableActions, CustomAddButton },

  data() {
    return {
      color: "",
      sortBy: "dd",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "type", label: "Type capacité", sortable: true },
        { key: "name", label: "Libellé capacité", sortable: true },
        { key: "niveau", label: "Niveau capacité", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
      loading: true,
      type: "",
      niveau: 0,

      description: "",
      selectedCapacite: null,
    };
  },
  validations: {
    selectedCapacite: {
      required,
    },
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    addInput() {
      this.tableItems = !this.tableItems.some((item) =>
        item.editMode ? item.editMode : false
      )
        ? [
            {
              editMode: true,
              // actions: ["confirm", "cancel"],
            },
            ...this.tableItems,
          ]
        : this.tableItems;
      this.currentPage = 1;
    },
    editItem(item) {
      this.$store
        .dispatch("composant/fetchComposantCapacites", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((challenge) => {
            return challenge.id === item.id
              ? {
                  ...challenge,
                  editMode: true,
                }
              : challenge;
          });
          this.name = item.name;
          this.description = item.description;
          this.status = item.status;
        });
    },
    cancelItem() {
      if (this.type === "" && this.selectedCapacite === null)
        this.$store.dispatch(
          "composant/fetchComposantCapacites",
          this.$route.params.id
        );
      else (this.type = ""), (this.selectedCapacite = null);
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("composant/updateComposant", {
              capacites: this.tableItems
                .filter((capacite) => capacite["@id"] !== item["@id"])
                .map((capacite) => capacite["@id"]),
              id: this.COMPOSANT_CAPACITES.id,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "composant/fetchComposantCapacites",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
    confirmItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        window.console.log(this.selectedCapacite);
        var items = this.tableItems
          .filter((item) => item["@id"])
          .map((item) => item["@id"]);
        items.push(this.selectedCapacite.value);
        this.$store
          .dispatch("composant/updateComposant", {
            capacites: items,
            id: this.COMPOSANT_CAPACITES.id,
          })
          .then(() => {
            this.$store
              .dispatch(
                "composant/fetchComposantCapacites",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire({
                  title: "La capacité est bien créée !",
                  type: "success",
                  confirmButtonText: "OK",
                });
                this.selectedCapacite = null;
                this.type = "";
              });
          });
      }
    },
  },
  created() {
    if (this.$route.params.id)
      this.$store
        .dispatch("composant/fetchComposantCapacites", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
    else {
      this.loading = false;
    }
    this.$store.dispatch("capaciteTechnique/fetchAllCapacites");
    this.$store.dispatch("capaciteSi/fetchAllCapacites");
  },
  computed: {
    ...mapGetters("capaciteTechnique", ["CAPACITES_TECHNIQUES"]), //get all status
    ...mapGetters("capaciteSi", ["CAPACITES_SI"]), //get all status
    ...mapGetters("composant", ["COMPOSANT_CAPACITES"]), //get all status

    rows() {
      return this.tableItems.length;
    },
    capacitesList() {
      if (this.type === "Capacité technique")
        return this.CAPACITES_TECHNIQUES.map((capacite) => ({
          text: capacite.name,
          value: capacite["@id"],
          niveau: capacite.niveau,
        })).filter(
          (capacite) =>
            !this.tableItems.find((item) => item["@id"] === capacite.value)
        );
      if (this.type === "Capacité si")
        return this.CAPACITES_SI.map((capacite) => ({
          text: capacite.name,
          value: capacite["@id"],
          niveau: capacite.niveau,
        })).filter(
          (capacite) =>
            !this.tableItems.find((item) => item["@id"] === capacite.value)
        );
      return [];
    },
  },

  watch: {
    COMPOSANT_CAPACITES() {
      this.tableItems = this.COMPOSANT_CAPACITES.capacites;
    },
    selectedCapacite() {
      if (this.selectedCapacite) this.niveau = this.selectedCapacite.niveau;
      else this.niveau = 0;
    },
  },
};
</script>

<style scoped>
.primary {
  background-color: #2a8947;
}

.danger {
  background-color: #c5221e;
}
</style>
>
